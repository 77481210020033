/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum CustomReportType {
    CUSTOM = 'custom',
    SALES_DETAILS = 'sales-details',
    CAMPAIGN_SUMMARY = 'campaign-summary',
    NEW_TO_ADVERTISER = 'new-to-advertiser',
    SALES_CHANNEL = 'sales-channel',
    SALES_PERFORMANCE = 'sales-performance',
    SITE_PERFORMANCE = 'site-performance',
    SELL_PERFORMANCE = 'sell-performance',
    MEASUREMENT_STUDY = 'measurement-study',
}
