/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GetAdGroupsQuery } from '../models/GetAdGroupsQuery';
import type { GetAdGroupsQueryResult } from '../models/GetAdGroupsQueryResult';
import type { GetBuilderFieldsConfigQuery } from '../models/GetBuilderFieldsConfigQuery';
import type { GetBuilderFieldsConfigQueryResult } from '../models/GetBuilderFieldsConfigQueryResult';
import type { GetCampaignsQuery } from '../models/GetCampaignsQuery';
import type { GetCampaignsQueryResult } from '../models/GetCampaignsQueryResult';
import type { GetDeliveredReportsQuery } from '../models/GetDeliveredReportsQuery';
import type { GetDeliveredReportsQueryResult } from '../models/GetDeliveredReportsQueryResult';
import type { GetSavedReportsQuery } from '../models/GetSavedReportsQuery';
import type { GetSavedReportsQueryResult } from '../models/GetSavedReportsQueryResult';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ReportingService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * @param requestBody
     * @returns GetCampaignsQueryResult OK
     * @throws ApiError
     */
    public postApiReportingGetCampaigns(
        requestBody?: GetCampaignsQuery,
    ): CancelablePromise<GetCampaignsQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Reporting/GetCampaigns',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetAdGroupsQueryResult OK
     * @throws ApiError
     */
    public postApiReportingGetAdGroups(
        requestBody?: GetAdGroupsQuery,
    ): CancelablePromise<GetAdGroupsQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Reporting/GetAdGroups',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetBuilderFieldsConfigQueryResult OK
     * @throws ApiError
     */
    public postApiReportingGetBuilderFieldsConfig(
        requestBody?: GetBuilderFieldsConfigQuery,
    ): CancelablePromise<GetBuilderFieldsConfigQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Reporting/GetBuilderFieldsConfig',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetDeliveredReportsQueryResult OK
     * @throws ApiError
     */
    public postApiReportingGetDeliveredReports(
        requestBody?: GetDeliveredReportsQuery,
    ): CancelablePromise<GetDeliveredReportsQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Reporting/GetDeliveredReports',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * @param requestBody
     * @returns GetSavedReportsQueryResult OK
     * @throws ApiError
     */
    public postApiReportingGetSavedReports(
        requestBody?: GetSavedReportsQuery,
    ): CancelablePromise<GetSavedReportsQueryResult> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/Reporting/GetSavedReports',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
