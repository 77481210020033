/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ReportingCategoryId {
    ACCOUNT = 'account',
    CAMPAIGN = 'campaign',
    AD_GROUP = 'ad_group',
    CREATIVES = 'creatives',
    PERFORMANCE = 'performance',
    DELIVERY = 'delivery',
    TIME = 'time',
    VIDEO = 'video',
    EMAIL = 'email',
}
