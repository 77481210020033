/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ReportingFieldId {
    CAMPAIGN_ID = 'campaign_id',
    CAMPAIGN = 'campaign',
    CREATIVE_ID = 'creative_id',
    CREATIVE = 'creative',
    AD_GROUP_FLIGHT = 'ad_group_flight',
    AD_GROUP_FLIGHT_ID = 'ad_group_flight_id',
    AD_GROUP = 'ad_group',
    AD_GROUP_ID = 'ad_group_id',
    AD_GROUP_FLIGHT_CODE = 'ad_group_flight_code',
    AD_GROUP_TRACKING_CODE = 'ad_group_tracking_code',
    ADVERTISER_ID = 'advertiser_id',
    ADVERTISER = 'advertiser',
    AVERAGE_FREQUENCY = 'average_frequency',
    AVERAGE_FREQUENCY_BY_HCP = 'average_frequency_by_hcp',
    BRAND = 'brand',
    BUDGET_CAP = 'budget_cap',
    BUDGET_TYPE = 'budget_type',
    CAMPAIGN_COMMENTS = 'campaign_comments',
    CAMPAIGN_TRACKING_CODE = 'campaign_tracking_code',
    CITY = 'city',
    CLICKS = 'clicks',
    CREATIVE_SIZE = 'creative_size',
    CREATIVE_TRACKING_CODE = 'creative_tracking_code',
    CTR = 'ctr',
    DATA_COST = 'data_cost',
    DATA_CPM = 'data_cpm',
    DAY = 'day',
    DEAL = 'deal',
    DEAL_ID = 'deal_id',
    DELIVERED = 'delivered',
    DEVICE_TYPE = 'device_type',
    DIRECT_SOLD_SPEND = 'direct_sold_spend',
    DMA = 'dma',
    END_DATE = 'end_date',
    FEES = 'fees',
    HOUR = 'hour',
    IMPRESSIONS = 'impressions',
    IMPRESSION_GOAL = 'impression_goal',
    MAKER = 'maker',
    MARKETER = 'marketer',
    MARKETER_ID = 'marketer_id',
    MEDIA_COST = 'media_cost',
    MEDIA_COST_PER_VIDEO_COMPLETE = 'media_cost_per_video_complete',
    MEDIA_CPM = 'media_cpm',
    MEDIA_TYPE = 'media_type',
    MONTH = 'month',
    OPERATING_SYSTEM = 'operating_system',
    POST_CLICK_CONVERSIONS = 'post_click_conversions',
    POST_VIEW_CONVERSIONS = 'post_view_conversions',
    POSTAL_CODE = 'postal_code',
    QUARTER = 'quarter',
    SELLER_MEMBER = 'seller_member',
    SELLER_MEMBER_ID = 'seller_member_id',
    SENT = 'sent',
    SERVED_VIDEOS = 'served_videos',
    SKIPPED_VIDEOS = 'skipped_videos',
    SITE_DOMAIN = 'site_domain',
    SPEND = 'spend',
    SPEND_PER_VIDEO_COMPLETE = 'spend_per_video_complete',
    START_DATE = 'start_date',
    STATE = 'state',
    TOTAL_CPM = 'total_cpm',
    UNIQUE_HCPS = 'unique_hcps',
    UNIQUES = 'uniques',
    UNSUBSCRIBED = 'unsubscribed',
    VIDEO_100 = 'video_100',
    VIDEO_25 = 'video_25',
    VIDEO_50 = 'video_50',
    VIDEO_75 = 'video_75',
    VIDEO_COMPLETION_RATE = 'video_completion_rate',
    VIDEO_PLAY_ERRORS = 'video_play_errors',
    VIEWABILITY = 'viewability',
    WEEK = 'week',
    YEAR = 'year',
}
